<template>
  <div class="panel-section-header flex-column align-items-start">
    <div class="flex justify-content-between">
      <div>
        <div class="caption mb-s1">
          {{ section }}
        </div>
        <div class="h1 text-primary">
          {{ pageTitle }}
        </div>
      </div>
    </div>
    <div class="w-12 sm:w-10 xl:w-8 mt-s3">
      <slot></slot>
    </div>
    <div class="flex justify-content-end" v-if="isButton">
      <Button
        class="button-base button-float mt-s4"
        @click="openExternalLink(howLink)"
        :label="$t('howItWorks')"
        icon="pi pi-chevron-right"
        icon-pos="right"
      >
        <span>How it works</span>
        <i class="pi pi-chevron-right ml-s2"></i>
      </Button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  section: String,
  pageTitle: String,
  howLink: String,
});

const isButton = false;
const openExternalLink = url => {
  window.open(url, '_blank');
};
</script>

<style scoped lang="scss"></style>
