import { toBigNumber } from '@/utils/bigNumber';
import BigNumber from 'bignumber.js';

const compareMixin = {
  methods: {
    eq(value1: string | BigNumber | number, value2: string | BigNumber | number): boolean {
      return toBigNumber(value1).eq(value2);
    },
    compareAddresses(value1: string, value2: string): boolean {
      return value1.toLowerCase() === value2.toLowerCase();
    },
  },
};

export default compareMixin;
