<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="description" name="description" :content="description" />
    <meta property="keywords" name="keywords" :content="keywords" />
  </Head>
  <div class="flex flex-column flex-grow-1">
    <SectionHeader :section="$t('addLiquidity')" :page-title="$t('portfolios')">
      {{ $t('portfoliosHeader') }}
    </SectionHeader>

    <div class="panel-frame mt-s3">
      <div class="flex flex-column md:flex-row md:align-items-center justify-content-between">
        <div class="flex justify-content-between align-items-center flex-grow-1 mb-s3">
          <div class="flex align-items-center">
            <span class="h3">{{ $t('portfolios') }}</span>
            <TooltipIcon :tooltip="$t('tooltip.portfolios')" />
          </div>
          <Switch
            v-if="$store.state.wallet.isInjected && isPortfoliosStatisticLoaded"
            :name="$t('portfolio.providedOnly')"
            name-pos="left"
            @change="onStakedOnly"
            :checked="isStakedOnly"
          />
        </div>
        <div
          class="flex align-items-center mb-s3 gutter-x-s3 sm:ml-s0"
          v-if="isPortfoliosStatisticLoaded"
        >
          <Dropdown
            v-model="sortBy"
            :options="sortField"
            option-label="name"
            option-value="value"
            :placeholder="$t('sortBy')"
            class="dropdown-button md:w-13rem flex-grow-1"
            panel-class="dropdown-list md:w-8rem mt-s1"
            @change="onSort"
          />
          <div>
            <Button class="button-base button-icon" @click="toggleSortDirection">
              <Icon :name="sortDirection" />
            </Button>
          </div>
          <div v-if="enableCrossChain()">
            <PortfoliosFilter @change="onFilter($event)" />
          </div>
        </div>
      </div>
      <SearchInput
        v-if="isSortedPortfoliosFound || isNoSearchResults"
        v-model="searchFilter"
        @update:model-value="onSearch($event)"
        :placeholder="searchInputPlaceholder"
        class="w-full mb-s3"
      />
      <PortfoliosLoadingPlaceholder v-if="isPortfoliosLoading" />

      <template v-else-if="isSortedPortfoliosFound">
        <Portfolio
          v-for="(portfolio, index) in sortedPortfolios"
          :portfolio="portfolio"
          :key="portfolio.contractAddress"
          :class="{ 'mt-s3': index !== 0 }"
          :open-after-created="
            compareAddresses(selectedPortfolioAddress, portfolio.contractAddress)
          "
          :ref="portfolio.contractAddress"
        />
      </template>
      <div v-else class="text-center panel-base justify-content-center text-muted">
        <span v-html="noPortfoliosMessage" />
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import Icon from '@/components/icon/Icon';
import SectionHeader from '@/components/SectionHeader';
import Switch from '@/components/switch/Switch';
import Portfolio from './portfolio/Portfolio';
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';
import SearchInput from '@/components/form/SearchInput';
import { mapGetters, mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { SEO_PORTFOLIOS } from '@/constants/seo';
import { Head } from '@vueuse/head';
import compareMixin from '@/mixins/compare.mixin';
import { nextTick } from 'vue';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import PortfoliosFilter from '@/views/pages/liquidity/portfolios/portfolio/portfoliosFilter/portfoliosFilter';
import { ENABLE_CROSS_CHAIN } from '@/helpers/crossChainEnv.helper';
import { PORTFOLIOS_FILTER_BY } from '@/views/pages/liquidity/portfolios/portfolio/models/constants';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { asyncFnCancelable } from '@/utils/promise';
import PortfoliosLoadingPlaceholder from '@/views/pages/liquidity/portfolios/PortfoliosLoadingPlaceholder.vue';

export default {
  name: 'Portfolios',
  components: {
    PortfoliosLoadingPlaceholder,
    PortfoliosFilter,
    TooltipIcon,
    Portfolio,
    SearchInput,
    SectionHeader,
    Switch,
    Head,
    Icon,
  },
  mixins: [compareMixin],
  watch: {
    '$route.params.address': {
      handler(value) {
        if (value) {
          this.selectedPortfolioAddress = value;
        }

        if (this.isPortfoliosStatisticLoaded) {
          nextTick(() => this.scrollToPortfolio(value));
        }
      },
      immediate: true,
    },
    // NOTE:
    // It invokes after first call of GET_PORTFOLIO_STATISTICS (init app) on Portfolios page
    // and when change route to Portfolios page
    isPortfoliosStatisticLoaded: {
      async handler() {
        if (!this.updateInterval) {
          try {
            // NOTE: while portfolios are updating, need wait when previous update will finish.
            if (this.isPortfoliosUpdated) {
              await asyncFnCancelable(this.updatePortfoliosInfo, this.updateController.signal);
            }
          } catch (error) {
            if (error.currentTarget instanceof AbortSignal) {
              return;
            }

            // NOTE:
            // When user open this page and UI try to update portfolios, error can happen.
            // When error then we will setInterval and show available portfolios.
            console.warn('[PORTFOLIOS:UPDATE] Error when portfolios updated: ', error);
          }

          this.updateInterval = setInterval(async () => {
            // NOTE: while portfolios are updating, need wait when previous update will finish.
            if (!this.isPortfoliosUpdated) return;

            await this.updatePortfoliosInfo();
          }, UPDATE_INTERVAL);
        }

        if (!this.isPortfoliosUpdated) {
          this.needScrollToPortfolio = true;
          return;
        }
        this.onSort();
        await nextTick(() => this.scrollToPortfolio(this.selectedPortfolioAddress));
      },
      immediate: true,
    },
    // NOTE: It invokes after UPDATE_PORTFOLIOS_INFO.
    isPortfoliosUpdated: {
      handler() {
        if (this.isPortfoliosStoreReady && this.isPortfoliosUpdated) {
          this.onSort();
          if (this.needScrollToPortfolio) {
            this.needScrollToPortfolio = false;
            nextTick(() => this.scrollToPortfolio(this.selectedPortfolioAddress));
          }
        }
      },
    },
    isPortfoliosStoreReady: {
      handler() {
        this.onSort();
      },
    },
  },
  beforeUnmount() {
    this.updateController.abort();
    this.updateController = null;

    clearInterval(this.updateInterval);
    this.updateInterval = null;
  },
  data() {
    return {
      updateController: new AbortController(),
      needScrollToPortfolio: false,
      updateInterval: null,
      isPortfolios: true,
      sortedPortfolios: null,
      selectedPortfolioAddress: '',
      portfoliosFilterBy: PORTFOLIOS_FILTER_BY.all,
      sortBy: 'liquidityInUSD',
      sortDirection: 'descending',
      sortField: [
        { name: this.$t('liquidityIn') + 'USD', value: 'liquidityInUSD' },
        { name: this.$t('volume') + ' 30' + this.$t('dayShort'), value: 'volume30d' },
        { name: this.$t('feesEarned') + ' 30' + this.$t('dayShort'), value: 'feesEarned30d' },
      ],
      searchFilter: '',
      isStakedOnly: false,
      filterByPortfoliosFilter: {
        [PORTFOLIOS_FILTER_BY.all]: portfolio => portfolio,
        [PORTFOLIOS_FILTER_BY.local]: portfolio =>
          portfolio.type === PortfolioSource.PORTFOLIO_LOCALE,
        [PORTFOLIOS_FILTER_BY.crosschain]: portfolio =>
          portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN,
      },
    };
  },
  created() {
    this.initFarming();
  },
  methods: {
    ...mapActions(MODULE_NAMES.PORTFOLIOS, {
      updatePortfoliosInfo: PORTFOLIO_ACTION_TYPES.UPDATE_PORTFOLIOS_INFO,
    }),
    ...mapActions(MODULE_NAMES.FARMING, {
      initFarming: FARMING_ACTION_TYPES.FARMING_INIT,
    }),
    onStakedOnly() {
      this.isStakedOnly = !this.isStakedOnly;
      this.onSort();
    },
    scrollToPortfolio(portfolioAddress) {
      const el = this.$refs[portfolioAddress]?.[0]?.$el;
      if (el) {
        const top = el.getBoundingClientRect().top + window.pageYOffset - 60;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    },
    onSearch(event) {
      this.searchFilter = event;
      this.onSort();
    },
    onSort() {
      let portfoliosData = this.getPortfolios.filter(
        this.filterByPortfoliosFilter[this.portfoliosFilterBy] ??
          this.filterByPortfoliosFilter[PORTFOLIOS_FILTER_BY.all],
      );
      if (this.isStakedOnly) {
        portfoliosData = portfoliosData.filter(item => {
          return item.getBalanceOfLp().isGreaterThan(0);
        });
      }

      this.sortedPortfolios = [
        ...portfoliosData.sort((prev, next) => {
          let prevValue, nextValue;

          switch (this.sortBy) {
            case 'liquidityInUSD':
              prevValue = prev.getValueInUSD(prev.portfolioTotalValueBase.toExact());
              nextValue = next.getValueInUSD(next.portfolioTotalValueBase.toExact());
              break;
            case 'volume30d':
              prevValue = new BigNumber(prev.volume30.toExact());
              nextValue = new BigNumber(next.volume30.toExact());
              break;
            case 'feesEarned30d':
              prevValue = new BigNumber(prev.fee30.toExact());
              nextValue = new BigNumber(next.fee30.toExact());
              break;
            default:
              break;
          }

          if (this.sortDirection === 'descending') {
            return nextValue - prevValue;
          } else {
            return prevValue - nextValue;
          }
        }),
      ];

      if (this.searchFilter) {
        const searchFilter = this.searchFilter.toLowerCase().trim();

        this.sortedPortfolios = [
          ...this.sortedPortfolios.filter(portfolio => {
            const portfoliosMatchingByName = portfolio.name.toLowerCase().includes(searchFilter);
            const portfoliosMatchingByTokens = portfolio.tokens.find(token => {
              return token.token.unwrapWETH().symbol.toLowerCase().includes(searchFilter);
            });
            const portfoliosMatchingByCrossChainTokens = portfolio.crossChainTokens.find(token => {
              return token.token.unwrapWETH().symbol.toLowerCase().includes(searchFilter);
            });
            return (
              portfoliosMatchingByName ||
              portfoliosMatchingByTokens ||
              portfoliosMatchingByCrossChainTokens
            );
          }),
        ];
      }
    },
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 'descending' ? 'ascending' : 'descending';
      this.onSort();
    },
    onFilter(event) {
      this.portfoliosFilterBy = event;
      this.onSort();
    },
    enableCrossChain() {
      return ENABLE_CROSS_CHAIN;
    },
  },
  computed: {
    ...mapGetters(MODULE_NAMES.PORTFOLIOS, ['getPortfolios']),
    title() {
      return SEO_PORTFOLIOS.TITLE;
    },
    description() {
      return SEO_PORTFOLIOS.DESCRIPTION;
    },
    keywords() {
      return SEO_PORTFOLIOS.KEYWORDS;
    },
    isPortfoliosUpdated() {
      return this.$store.state.portfolios.isPortfoliosUpdated;
    },
    isPortfoliosStatisticLoaded() {
      return this.$store.state.portfolios.isStatisticLoaded;
    },
    isPortfoliosStoreReady() {
      return this.$store.state.portfolios.isStoreReady;
    },
    searchInputPlaceholder() {
      return this.$store.state.layout.isMobile
        ? this.$t('search')
        : this.$t('portfolio.searchPlaceholder');
    },
    isPortfoliosLoading() {
      return (
        !this.isPortfoliosSorted || (!this.isPortfoliosStoreReady && !this.isSortedPortfoliosFound)
      );
    },
    isPortfoliosSorted() {
      return this.sortedPortfolios !== null;
    },
    isSortedPortfoliosFound() {
      return this.sortedPortfolios?.length > 0;
    },
    isNoSearchResults() {
      return !this.isSortedPortfoliosFound && !!this.searchFilter;
    },
    noPortfoliosMessage() {
      const noSearchResults = this.$t('portfolio.noSearchResults', {
        searchText: this.searchFilter,
        escapeParameterHtml: true,
      });
      const noStakedPortfolios = this.$t('portfolio.emptyList', { escapeParameterHtml: true });
      return this.isNoSearchResults ? noSearchResults : noStakedPortfolios;
    },
  },
};
</script>

<style scoped></style>
