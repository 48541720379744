import { fromWei } from '@/sdk/utils';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { useTokensBalances } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useTokensBalance';
import { EasyModeToken } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';
import { computed } from 'vue';

/**
 Returns sum of user portfolio selected tokens balances in base token relative units equivalent
 */
export function useSelectedTokensBalance(useDepositPrice = false) {
  const { easyModeForm, selectedTokens, getTokenInfoByFormToken } = useEasyModeForm();
  const { getTokenBalancesWei } = useTokensBalances(useDepositPrice);

  const tokens = computed(() =>
    selectedTokens.value.map(t => getTokenInfoByFormToken(t as EasyModeToken)),
  );

  return computed(() =>
    fromWei(getTokenBalancesWei(tokens).value, easyModeForm.portfolio.baseToken.decimals),
  );
}
