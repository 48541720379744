import { getRouterAddress } from '@/helpers/address.helper';
import { getRouterContract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Token } from '@/sdk/entities/token';
import { applySlippageInPercents, compareTokenAddresses, toWei } from '@/sdk/utils';
import { MODULE_NAMES } from '@/store';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { EasyModeAddLiquidityForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { EasyModeWithdrawToken } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import BigNumber from 'bignumber.js';
import { useStore } from 'vuex';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { ethers } from 'ethers';
import { useBalances } from '@/store/modules/tokens/useBalances';

export function useEasyModeTransactions() {
  const store = useStore();
  const { easyModeForm } = useEasyModeForm();
  const { updateTokenBalances } = useBalances();

  async function addLiquidity(
    portfolioFormTokens: SelectedPortfolioTokens,
  ): Promise<ethers.providers.TransactionReceipt> {
    const tx = await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.PORTFOLIO_ADD_LIQUIDITY,
      {
        selectedTokens: portfolioFormTokens,
        portfolio: easyModeForm.portfolio,
        lpAmountOut: (easyModeForm as EasyModeAddLiquidityForm).lpAmountOut,
        destinationChainId: easyModeForm.destinationChainId,
      },
    );

    await updatePortfolios();

    return tx;
  }

  async function withdraw(
    withdrawTokens: { token: Token; amount: BigNumber }[],
    LPSum: BigNumber,
  ): Promise<ethers.providers.TransactionReceipt> {
    const amounts = withdrawTokens.map(withdrawToken =>
      toWei(withdrawToken.amount, withdrawToken.token.decimals),
    );

    let roundedLptAmountsSum = new BigNumber(0);

    const lptAmounts = withdrawTokens.map(({ token }) => {
      const formToken = (easyModeForm.tokens as EasyModeWithdrawToken[]).find(formToken =>
        compareTokenAddresses(formToken.address, token.address),
      )!;

      const roundedWeiToWithdraw = formToken.lpWeiToWithdraw.integerValue(BigNumber.ROUND_DOWN);
      roundedLptAmountsSum = roundedLptAmountsSum.plus(roundedWeiToWithdraw);

      return roundedWeiToWithdraw.toFixed();
    });

    const dust = toWei(LPSum).minus(roundedLptAmountsSum);
    lptAmounts[0] = new BigNumber(lptAmounts[0])
      .plus(dust)
      .integerValue(BigNumber.ROUND_DOWN)
      .toFixed();

    const minAmountsOut: string[] = amounts.map(amount =>
      applySlippageInPercents(
        amount,
        store.state.portfolios.withdrawSettings.slippageTolerance,
      ).toFixed(0),
    );

    const lpToken: Token = new Token(
      easyModeForm.portfolio.baseToken.chainId,
      easyModeForm.portfolio.lpTokenAddress,
      18,
      'LPT',
    );

    await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE,
      {
        token: lpToken,
        amountInWei: toWei(LPSum),
        destinationChainId: easyModeForm.destinationChainId,
      },
    );

    console.log('removeLiquidityFromPortfolio', [
      withdrawTokens.map(withdrawToken => withdrawToken.token.address),
      lptAmounts,
      minAmountsOut,
      store.state.wallet.account,
      safeDateNowPlusEstimatedMinutes(store.state.portfolios.withdrawSettings.transactionDeadline),
      easyModeForm.portfolio.contractAddress,
      easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_LOCALE
        ? 0
        : easyModeForm.destinationChainId,
    ]);
    console.log('minAmountsOut of tokens and decimals', [
      withdrawTokens.map(withdrawToken => withdrawToken.token.symbol),
      withdrawTokens.map(withdrawToken => withdrawToken.token.decimals),
      minAmountsOut,
    ]);

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'removeLiquidityFromPortfolio(address[],uint256[],uint256[],address,uint256,address,uint256)',
      [
        withdrawTokens.map(withdrawToken => withdrawToken.token.address),
        lptAmounts,
        minAmountsOut,
        store.state.wallet.account,
        safeDateNowPlusEstimatedMinutes(
          store.state.portfolios.withdrawSettings.transactionDeadline,
        ),
        easyModeForm.portfolio.contractAddress,
        easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_LOCALE
          ? 0
          : easyModeForm.destinationChainId,
      ],
    );

    const tx = await result.wait();

    await updatePortfolios();

    return tx;
  }

  async function updatePortfolios() {
    await store.dispatch(MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS);
    await updateTokenBalances();
  }

  return {
    addLiquidity,
    withdraw,
  };
}
