import { Emitter, Mitt } from '@/utils/emitter';
import { NotificationEvent } from './models/event';

const getEmitter = <P>(): Emitter<NotificationEvent<P>> => {
  const emitter = Mitt<NotificationEvent<P>>();

  return emitter;
};

type ProcessEventFn<P> = (emitter: Emitter<NotificationEvent<P>>) => Promise<any>;
type Notification<P> = {
  step: string;
  progress: (payload: P) => void;
  completed: (payload: P) => void;
  error: (payload: P) => void;
};
type EventNotificationParam<P> = {
  notifications: Notification<P>[];
  showError?: <P>(payload: P) => void;
};

export const withEventNotification = <P>(
  doProcessing: ProcessEventFn<P>,
  notificationOptions: EventNotificationParam<P>,
) => {
  const emitter = getEmitter<P>();

  emitter.once('error', payload => {
    console.error(payload);

    notificationOptions.showError?.(payload);
  });

  const { notifications } = notificationOptions;

  notifications.forEach(notification => {
    emitter.once(`${notification.step}-progress`, payload => notification.progress(payload));
    emitter.once(`${notification.step}-completed`, payload => notification.completed(payload));
    emitter.once(`${notification.step}-error`, payload => notification.error(payload));
  });

  doProcessing(emitter);
};
