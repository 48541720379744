<template>
  <div class="flex flex-wrap gutter-y-s3 flex-grow-1">
    <div class="w-12 sm:w-4">
      <Caption
        :caption="$t('liquidity')"
        :tooltip="$t('tooltip.liquidity')"
        class="mb-s1 md:mb-s2"
      />
      <div class="flex flex-column">
        <div>
          ${{ format(portfolio.getValueInUSD(portfolio.portfolioTotalValueBase.toExact())) }}
        </div>
        <div class="text-sm mt-s1 sm:mt-s0">
          {{ format(portfolio.portfolioTotalValueBase.toExact()) }}
          {{ portfolio.baseToken.unwrapWETH().symbol }}
        </div>
      </div>
    </div>
    <div class="w-12 sm:w-4">
      <Caption
        :caption="$t('volume') + ' 30' + $t('dayShort')"
        :tooltip="$t('tooltip.volume30')"
        class="mb-s1 md:mb-s2"
      />
      <div class="flex flex-column">
        <div>${{ format(portfolio.volume30.toExact()) }}</div>
      </div>
    </div>
    <div class="w-12 sm:w-4">
      <Caption
        :caption="$t('feesEarned') + ' 30' + $t('dayShort')"
        :tooltip="$t('tooltip.feesEarned')"
        class="mb-s1 md:mb-s2"
      />
      <div class="flex flex-column">
        <div>${{ format(portfolio.fee30.toExact()) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Caption from '@/components/Caption';
import { Portfolio } from '@/sdk/entities/portfolio';
import { mapGetters } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';

export default {
  name: 'PortfolioHeaderData',
  mixins: [formatNumbersMixin],
  components: { Caption },
  props: {
    portfolio: Portfolio,
  },
  computed: {
    ...mapGetters(MODULE_NAMES.TOKENS, ['']),
  },
};
</script>

<style scoped></style>
