<template>
  <ControlPanelCollapse
    :name="portfolio.name"
    :contract-url="getContractUrl()"
    :expanded="openAfterCreated"
    :brand="brandedPortfolioInfo?.brand"
    :brand-icon="brandedPortfolioInfo?.icon"
    :crosschain="isCrosschainPortfolio"
  >
    <template v-slot:header-title>
      <TokenGroupIcon :tokens="getPortfolioTokens()" />
    </template>
    <template v-slot:header-data>
      <PortfolioHeaderData :portfolio="portfolio" />
    </template>
    <PortfolioCompound :portfolio="portfolio" />
  </ControlPanelCollapse>
</template>

<script>
import PortfolioHeaderData from './portfolioHeader/PortfolioHeaderData';
import PortfolioCompound from './portfolioCompound/PortfolioCompound';
import { Portfolio } from '@/sdk/entities/portfolio';
import { getScanLink } from '@/sdk/utils';
import ControlPanelCollapse from '@/components/ControlPanelCollapse';
import TokenGroupIcon from '@/components/token/TokenGroupIcon';
import brandedPortfoliosConfig from '@/helpers/brandedPortfoliosConfig.json';
import compareMixin from '@/mixins/compare.mixin';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';

export default {
  name: 'Portfolio',
  components: {
    TokenGroupIcon,
    ControlPanelCollapse,
    PortfolioCompound,
    PortfolioHeaderData,
  },
  mixins: [compareMixin],
  props: {
    portfolio: Portfolio,
    openAfterCreated: Boolean,
  },
  methods: {
    getContractUrl() {
      return getScanLink(this.portfolio.contractAddress, 'address');
    },
    getPortfolioTokens() {
      const tokens = this.portfolio.tokens.map(token => token.token);
      const crossChainTokens = this.portfolio.crossChainTokens.map(token => token.token);
      return [...tokens, ...crossChainTokens];
    },
  },
  computed: {
    brandedPortfolioInfo() {
      return brandedPortfoliosConfig.find(brandedPortfolio =>
        this.compareAddresses(brandedPortfolio.contractAddress, this.portfolio.contractAddress),
      );
    },
    isCrosschainPortfolio() {
      return this.portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN;
    },
  },
};
</script>

<style scoped></style>
