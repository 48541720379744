import { CARDANO_NATIVE_ADDRESS } from '@/store/modules/tokens/constants/CARDANO_NATIVE_ADDRESS';
import { CardanoToken } from '@/store/modules/tokens/models/cardano-token';
import BigNumber from 'bignumber.js';

export function isCardanoNativeToken(token: CardanoToken | string): boolean {
  if (typeof token === 'string') {
    return token === CARDANO_NATIVE_ADDRESS;
  }

  return token.address === CARDANO_NATIVE_ADDRESS;
}
