<template>
  <div>
    <div class="flex align-items-start" :class="getClass()">
      <Icon
        :name="icon"
        size="16"
        v-if="icon"
        :class="iconColor ? `text-${iconColor} mr-s2` : 'mr-s2'"
      />
      <div class="font-normal">
        <slot name="notice-text">
          {{ text }}
        </slot>
        <TooltipIcon v-if="tooltip" :tooltip="tooltip" inline />
      </div>
    </div>
    <div class="mt-s3" v-if="!!$slots['default']">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/icon/Icon';
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';

const props = defineProps({
  text: String,
  icon: String,
  type: {
    type: String,
    default: 'inherit',
  },
  size: {
    type: String,
    default: 'sm',
  },
  tooltip: {
    type: String,
    default: null,
  },
  iconColor: String,
});

const getClass = () => {
  return `text-${props.type} text-${props.size}`;
};
</script>

<style scoped></style>
