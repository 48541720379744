<template>
  <div class="flex flex-column">
    <div class="flex align-items-center mb-s2">
      <span class="text-gray">{{ name }}</span>
      <TooltipIcon :tooltip="tooltip" v-if="tooltip" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';
export default {
  name: 'SettingItem',
  components: { TooltipIcon },
  props: {
    name: String,
    tooltip: String,
  },
};
</script>

<style scoped></style>
