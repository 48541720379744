import { fromWei, toWei } from '@/sdk/utils';
import BigNumber from 'bignumber.js';

const weiMixin = {
  methods: {
    fromWei(value: string | BigNumber | number, decimals?: number) {
      return fromWei(value, decimals);
    },
    toWei(value: string | BigNumber | number, decimals?: number) {
      return toWei(value, decimals);
    },
  },
};

export default weiMixin;
