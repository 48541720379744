<template>
  <section class="callout-container" :class="'callout-container_' + type">
    <div class="callout-content" :class="contentClass">
      <slot name="content"></slot>
      <slot></slot>
    </div>
    <div>
      <slot name="aside"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Callout',
  props: {
    type: {
      validator(value) {
        return ['success', 'warning', 'danger'].includes(value);
      },
    },
    contentClass: String,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/styles';
.callout-container {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 11px 12px;

  font-size: $font-size-sm;

  &_success {
    background-color: var(--success-10);

    .callout-content {
      color: var(--success);
    }
  }

  &_warning {
    background-color: var(--warning-10);

    .callout-content {
      color: var(--warning);
    }
  }

  &_danger {
    background-color: var(--danger-10);

    .callout-content {
      color: var(--danger);
    }
  }
}

.callout-content {
  flex: 1 1 auto;
  margin-right: 15px;
}
</style>
