import { fromWei } from '@/sdk/utils';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { useTokensBalances } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useTokensBalance';
import { computed } from 'vue';

/**
  Returns sum of user portfolio tokens balances in base token relative units equivalent
 */
export function useActiveTokensBalance(useDepositPrice = false) {
  const { easyModeForm, getPortfolioAvailableTokens } = useEasyModeForm();
  const { getTokenBalancesWei } = useTokensBalances(useDepositPrice);

  const tokens = getPortfolioAvailableTokens(easyModeForm.portfolio);

  return computed(() =>
    fromWei(getTokenBalancesWei(tokens).value, easyModeForm.portfolio.baseToken.decimals),
  );
}
