<template>
  <div>
    <div class="flex align-items-center justify-content-end gutter-x-s2 -ml-s2 sm:ml-s0">
      <div class="wrapper" v-for="percent in percentage" :key="percent">
        <Button
          :label="percent === '100' ? 'MAX' : percent + '%'"
          :class="[{ active: isActive(percent) }, getButtonClass()]"
          @click="setPercentValue(percent)"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentButton',
  emits: ['change'],
  props: {
    activePercent: String,
    disabled: Boolean,
    buttonStyle: {
      type: String,
      default: 'max',
    },
  },
  data: function () {
    return {
      percentage: ['25', '50', '75', '100'],
    };
  },
  methods: {
    setPercentValue(value) {
      this.$emit('change', value);
    },
    isActive(percent) {
      return percent === this.activePercent;
    },
    getButtonClass() {
      return this.buttonStyle === 'rounded'
        ? `button-${this.buttonStyle} button-sm button-base h-2rem`
        : `button-${this.buttonStyle} percent-button`;
    },
  },
};
</script>

<style scoped lang="scss">
.mobile .percent-button {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
