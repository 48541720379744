import { LOSS } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/LOSS';
import BigNumber from 'bignumber.js';
import {
  HUGE_LOSS_LIMIT_PERCENT,
  SMALL_LOSS_LIMIT_PERCENT,
  SMALL_LOSS_LIMIT_USD,
} from './constants/LOSS_LIMIT';

export function lossIsZero(lossUSD: BigNumber, lossPercent: BigNumber): boolean {
  return lossUSD.lt(SMALL_LOSS_LIMIT_USD) && lossPercent.lt(SMALL_LOSS_LIMIT_PERCENT);
}

export function lossIsSmall(lossPercent: BigNumber): boolean {
  return lossPercent.lt(HUGE_LOSS_LIMIT_PERCENT);
}

export function lossIsHuge(lossPercent: BigNumber): boolean {
  return lossPercent.gte(HUGE_LOSS_LIMIT_PERCENT);
}

export function getLossType(lossUSD: BigNumber, lossPercent: BigNumber): LOSS {
  if (lossIsZero(lossUSD, lossPercent)) {
    return LOSS.ZERO;
  }

  if (lossIsHuge(lossPercent)) {
    return LOSS.HUGE;
  }

  return LOSS.SMALL;
}
