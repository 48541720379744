export function moveItem<T>(array: T[], oldIndex: number, newIndex: number): T[] {
  const arrayWithoutMoved = array
    .slice(0, oldIndex)
    .concat(array.slice(oldIndex + 1, array.length));

  return arrayWithoutMoved
    .slice(0, newIndex)
    .concat(array[oldIndex])
    .concat(arrayWithoutMoved.slice(newIndex, arrayWithoutMoved.length));
}

export function findLastIndex<T>(
  array: T[],
  predicate: (item: T, index: number, collection: T[]) => boolean,
): number {
  const positionFromEnd = array.concat().reverse().findIndex(predicate);

  if (positionFromEnd === -1) {
    return -1;
  }

  return array.length - 1 - positionFromEnd;
}

export function last<T>(array: T[]): T | undefined {
  if (array.length === 0) {
    return undefined;
  }

  return array[array.length - 1];
}
