<template>
  <Button @click="onDialogOpen" :disabled="disabled" v-if="type === 'button'" :class="btnClass">
    <Icon name="setting" size="20" />
  </Button>
  <ControlIcon name="setting" size="24" :on-click="onDialogOpen" v-else />

  <Dialog
    v-model:visible="isShowDialog"
    header="Transaction settings"
    :modal="true"
    dismissableMask
    class="dialog-setting"
    @show="$emit('onShowSetting', isShowDialog)"
    @hide="$emit('onShowSetting', isShowDialog)"
  >
    <div class="p-dialog-body my-s4">
      <SettingItem name="Slippage tolerance" :tooltip="$t('tooltip.slippageTolerance')">
        <div class="flex align-items-center flex-wrap md:flex-nowrap">
          <Button
            v-for="value in toleranceValues"
            :label="formatValue(value)"
            :key="value"
            class="p-button-rounded mr-2 mb-2 button-base"
            :class="{ active: +settings.slippageTolerance === value }"
            @click="setSlippageTolerance(value)"
          />
          <div class="flex align-items-center mb-s2">
            <FormattedInput
              v-model="settings.slippageTolerance"
              class="input-panel w-4rem text-center mr-1"
              @change="onChangeSlippageTolerance"
            />
            <span class="text-gray"> %</span>
          </div>
        </div>
        <div class="flex text-info" v-if="isSlippageToleranceTooLow">
          <Icon name="infoStar" size="20" class="mr-1" />
          <span class="text-sm transaction-warning">{{ $t('yourTransactionMayFail') }}</span>
        </div>
        <div class="flex text-info" v-if="isSlippageToleranceTooHigh">
          <Icon name="infoStar" size="20" class="mr-1" />
          <span class="text-sm transaction-warning">{{ $t('yourTransactionMayBeFrontrun') }}</span>
        </div>
      </SettingItem>

      <SettingItem name="Transaction deadline" :tooltip="$t('tooltip.deadline')" class="mt-s5">
        <div class="flex align-items-center mt-s2">
          <FormattedInput
            v-model="settings.transactionDeadline"
            class="w-4rem text-center input-panel"
            @change="onChangeTransactionDeadlineInput"
          />
          <span class="ml-s2 text-muted">min</span>
        </div>
      </SettingItem>
    </div>

    <div class="dialog-footer dialog-footer-two-btn">
      <Button class="button-base" label="Cancel" @click="onDialogClose" />
      <Button
        class="button-primary"
        :disabled="isDisableApplyButton"
        label="Apply"
        @click="onApplySetting"
      />
    </div>
  </Dialog>
</template>

<script>
import SettingItem from './SettingItem';
import ControlIcon from '@/components/icon/ControlIcon';
import Icon from '@/components/icon/Icon';
import FormattedInput from '@/components/FormattedInput';
import validateTransactionSettingsMixin from '@/mixins/validate-transaction-settings.mixin';
import { cloneStruct, compareObjects } from '@/utils/object';
import {
  MIN_NORMAL_SLIPPAGE_TOLERANCE_PERCENT,
  MAX_NORMAL_SLIPPAGE_TOLERANCE_PERCENT,
} from '@/constants/transactionSettings';

export default {
  name: 'Settings',
  mixins: [validateTransactionSettingsMixin],
  components: { ControlIcon, Icon, SettingItem, FormattedInput },
  props: {
    type: { type: String, default: 'icon' },
    formSettings: Object,
    disabled: { type: Boolean, default: true },
    onApplySettings: Function,
    btnClass: String,
  },
  emits: ['onShowSetting'],
  data() {
    return {
      isShowDialog: false,
      isDisableApplyButton: false,
      settings: {
        slippageTolerance: null,
        transactionDeadline: null,
      },
      toleranceValues: [0.1, 0.5, 1, 2, 3],
    };
  },
  watch: {
    settings: {
      handler() {
        this.isDisableApplyButton = compareObjects(this.settings, this.formSettings);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    onDialogOpen() {
      this.isShowDialog = true;
      this.settings = Object.assign(this.settings, this.formSettings);
      this.isDisableApplyButton = compareObjects(this.settings, this.formSettings);
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    formatValue(value) {
      return value.toFixed(1) + '%';
    },
    setSlippageTolerance(value) {
      this.settings.slippageTolerance = value.toString();
    },
    onToggleMultiHops(event) {
      this.settings.enableMultiHops = event;
    },
    onApplySetting() {
      this.onApplySettings(cloneStruct({ settings: this.settings }));
      this.onDialogClose();
    },
    onChangeTransactionDeadlineInput() {
      this.settings.transactionDeadline = this.validateTransactionDeadline(
        this.settings.transactionDeadline,
      );
    },
    onChangeSlippageTolerance() {
      this.settings.slippageTolerance = this.validateSlippageTolerance(
        this.settings.slippageTolerance,
      );
    },
  },
  computed: {
    isSlippageToleranceTooLow() {
      return +this.settings.slippageTolerance < MIN_NORMAL_SLIPPAGE_TOLERANCE_PERCENT;
    },
    isSlippageToleranceTooHigh() {
      return +this.settings.slippageTolerance > MAX_NORMAL_SLIPPAGE_TOLERANCE_PERCENT;
    },
  },
};
</script>

<style scoped>
.transaction-warning {
  line-height: inherit;
}
</style>
