import { MayBeRef } from '@/helpers/utils';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { TokenInfo } from '@/sdk/entities/portfolio';
import { BIG_ZERO } from '@/utils/bigNumber';
import { nativeBalanceWeiWithoutFee } from '@/utils/blockchain';
import BigNumber from 'bignumber.js';
import { computed, ComputedRef, unref } from 'vue';
import { useBalances } from '@/store/modules/tokens/useBalances';

/**
 Returns sum of user portfolio tokens balances in base token relative units equivalent
 */
export function useTokensBalances(useDepositPrice = false) {
  const { balances: allBalances } = useBalances();

  const getTokenBalancesWei = (tokens: MayBeRef<TokenInfo[]>): ComputedRef<BigNumber> => {
    const balances: ComputedRef<Record<string, { balance: TokenAmount }>> = computed(
      () => allBalances ?? {},
    );

    return computed(() =>
      unref(tokens).reduce((sumBalance, tokenInfo) => {
        let tokenBalance =
          balances.value[tokenInfo.token.unwrapWETH().symbol!]?.balance.raw.toString();

        if (!tokenBalance) {
          console.groupCollapsed(
            `=== Balance ${tokenInfo.token.symbol} | ${tokenInfo.token.unwrapWETH().symbol} ===`,
          );
          console.log(`tokenBalance (raw): `, tokenBalance);
          console.groupEnd();

          return sumBalance;
        }

        if (tokenInfo.token.unwrapWETH().isETHToken()) {
          tokenBalance = nativeBalanceWeiWithoutFee(tokenBalance).toFixed();
        }

        const price = useDepositPrice ? tokenInfo.getDepositPrice() : tokenInfo.price;

        const baseTokenWeiEquivalent = new BigNumber(tokenBalance)
          .multipliedBy(price)
          .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);

        console.groupCollapsed(
          `=== Balance ${tokenInfo.token.symbol} | ${tokenInfo.token.unwrapWETH().symbol} ===`,
        );
        console.log(`token isETH : `, tokenInfo.token.unwrapWETH().isETHToken());
        console.log(`token : `, tokenInfo.token.address);
        console.log(`tokenBalance (raw) : `, tokenBalance);
        console.log(
          `${tokenInfo.token.symbol} ${useDepositPrice ? 'deposit' : ''} price `,
          `in ${tokenInfo.baseToken.symbol} : `,
          price.toString(),
        );
        console.log(`baseTokenWeiEquivalent : `, baseTokenWeiEquivalent.toString());
        console.groupEnd();

        return sumBalance.plus(baseTokenWeiEquivalent);
      }, BIG_ZERO),
    );
  };

  return {
    getTokenBalancesWei,
  };
}
