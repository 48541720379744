import { LP_TOKEN_DECIMALS } from '@/sdk/entities/constants/LP_TOKEN_DECIMALS';
import { fromWei } from '@/sdk/utils';
import { BIG_ZERO } from '@/utils/bigNumber';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { computed } from 'vue';
import { useStore } from 'vuex';

const { easyModeForm } = useEasyModeForm();

export function useStakedValue() {
  const { state } = useStore();

  return computed(() =>
    fromWei(
      state.farming.lp[easyModeForm.portfolio.lpTokenAddress]?.deposited || BIG_ZERO,
      LP_TOKEN_DECIMALS,
    ),
  );
}
