<template>
  <div class="flex align-items-center">
    <div class="caption mr-s2">
      {{ $t(`portfolio.easyModeTranslations.${action}.chosenTokens`) }}:
    </div>
    <TokenGroupIcon :tokens="tokens" />
  </div>
</template>

<script>
import TokenGroupIcon from '@/components/token/TokenGroupIcon';
export default {
  name: 'ChosenTokens',
  components: { TokenGroupIcon },
  props: {
    action: String,
    tokens: Array,
  },
};
</script>

<style scoped></style>
